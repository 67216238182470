$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-menu: "\e904";
$icon-arrow-left: "\e900";
$icon-arrow-right: "\e901";
$icon-arrow-right2: "\e91a";
$icon-arrow-down2: "\e91b";
$icon-arrow-left2: "\e91c";
$icon-arrow-up2: "\e91d";
$icon-arrow-left1: "\e902";
$icon-arrow-right1: "\e903";
$icon-arrow-up1: "\e917";
$icon-arrow-down1: "\e916";

