@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?sssssbpceabss') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?sssssbpceabss') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?sssssbpceabss') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?sssssbpceabs##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


.icomoon-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2; 
  }
}
.icon-arrow-down2 {
  &:before {
    content: $icon-arrow-down2; 
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2; 
  }
}
.icon-arrow-up2 {
  &:before {
    content: $icon-arrow-up2; 
  }
}
.icon-arrow-left1 {
  &:before {
    content: $icon-arrow-left1; 
  }
}
.icon-arrow-right1 {
  &:before {
    content: $icon-arrow-right1; 
  }
}
.icon-arrow-up1 {
  &:before {
    content: $icon-arrow-up1; 
  }
}
.icon-arrow-down1 {
  &:before {
    content: $icon-arrow-down1; 
  }
}
