.hero-area {
  position: relative;
  overflow: hidden;
  // margin-top: -5rem;
  height: 481px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url("../img/bg.jpg");
  background-attachment: fixed;

  @include breakpoint(large) {
    height: 1160px;
    background-position: center center;
    min-height: 700px;
  }

  img {
    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
}

.hero__content {
  position: absolute;
  top: 50%;
  width: 80%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  #{$default-direction}: 0;
  #{$opposite-direction}: 0;

  @include breakpoint(medium down) {
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    margin: 0 auto;
    text-align: center;
  }

  @include breakpoint(large) {
    width: 73%;
    top: 60%;
    -webkit-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
    transform: translateY(-60%);
  }

  &-msg {
    color: $white;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include breakpoint(large) {
      font-size: 80px;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    h1 {
      font-weight: 700;
      margin-bottom: 42px;
      // text-decoration-thickness: 3px;
      // text-underline-offset: 6px;
      // text-shadow: -4px -2px 0px rgba(#fe2c55, 1);
      font-family: $font-secondary;
      font-size: 40px;
      @include breakpoint(large) {
        font-size: 60px;
        [dir="rtl"] & {
          font-weight: 700;
        }
      }

      div {
        font-weight: 500;
        display: inline-block;
        position: relative;

        [dir="rtl"] & {
          font-weight: 400;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          #{$default-direction}: 0;
          #{$opposite-direction}: 0;
          background-color: $white;
          height: 3px;
          width: 100%;

          @include breakpoint(large) {
            bottom: 10px;
          }
        }
      }
    }

    &-date {
      margin-bottom: 42px;
      @include breakpoint(large) {
        font-size: 34px;
        line-height: 36px;

        [dir="rtl"] & {
          font-weight: 400;
          font-size: 40px;
          line-height: 36px;
        }
      }
    }
  }

  .button {
    border: 2px solid $primary-color;
    padding: 15px 20px;
    margin-bottom: 52px;
    color: $white;
    font-weight: normal;
    background-color: rgba(0, 0, 0, 0.5);
    @include breakpoint(large) {
      font-size: 28px;
      line-height: 36px;
      padding: 18px 40px;
    }
    &:hover,
    &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ffffff;
    @include breakpoint(large) {
      font-size: 28px;
      line-height: 34px;
    }

    a {
      color: $secondary-color;
    }

    span {
      font-weight: bold;
    }
  }
}

.hero__media {
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;

  img {
    width: 100%;
    //  height: 100%;
  }

  //   .jwplayer {
  //     z-index: 1;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     object-fit: cover;
  //     width: 100% !important;
  //     height: 100% !important;
  //   }
  //   .jwplayer.jw-flag-aspect-mode {
  //     height: 100% !important;
  //   }
}
