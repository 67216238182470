$font-path: "../fonts";

@import "icomoon";

@font-face {
  font-family: "Avenir LT Std";
  src: url("#{$font-path}/AvenirLTStd-Medium.woff2") format("woff2"),
    url("#{$font-path}/AvenirLTStd-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("#{$font-path}/AvenirLTStd-Heavy.woff2") format("woff2"),
    url("#{$font-path}/AvenirLTStd-Heavy.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("#{$font-path}/AvenirLTStd-Book.woff2") format("woff2"),
    url("#{$font-path}/AvenirLTStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bahij TheSansArabic";
  src: url("#{$font-path}/BahijTheSansArabic-Bold.woff2") format("woff2"),
    url("#{$font-path}/BahijTheSansArabic-Bold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bahij TheSansArabic";
  src: url("#{$font-path}/BahijTheSansArabic-Light.woff2") format("woff2"),
    url("#{$font-path}/BahijTheSansArabic-Light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bahij TheSansArabic";
  src: url("#{$font-path}/BahijTheSansArabic-ExtraBold.woff2") format("woff2"),
    url("#{$font-path}/BahijTheSansArabic-ExtraBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-It.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-It.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-It.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-Regular.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-Bold.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-Bold.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-Cn.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-Cn.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-Cn.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-DemiCnIt.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-DemiCnIt.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-DemiCnIt.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-Demi.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-Demi.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("#{$font-path}/AvenirNextLTPro-DemiIt.woff2") format("woff2"),
    url("#{$font-path}/AvenirNextLTPro-DemiIt.woff") format("woff"),
    url("#{$font-path}/AvenirNextLTPro-DemiIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TikTok Text";
  src: url("#{$font-path}/TikTokText-Regular.eot");
  src: url("#{$font-path}/TikTokText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/TikTokText-Regular.woff2") format("woff2"),
    url("#{$font-path}/TikTokText-Regular.woff") format("woff"),
    url("#{$font-path}/TikTokText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TikTok Text";
  src: url("#{$font-path}/TikTokText-Bold.eot");
  src: url("#{$font-path}/TikTokText-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/TikTokText-Bold.woff2") format("woff2"),
    url("#{$font-path}/TikTokText-Bold.woff") format("woff"),
    url("#{$font-path}/TikTokText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TikTok Text";
  src: url("#{$font-path}/TikTokText-Medium.eot");
  src: url("#{$font-path}/TikTokText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/TikTokText-Medium.woff2") format("woff2"),
    url("#{$font-path}/TikTokText-Medium.woff") format("woff"),
    url("#{$font-path}/TikTokText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TikTok Display";
  src: url("#{$font-path}/TikTokDisplay-Regular.eot");
  src: url("#{$font-path}/TikTokDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/TikTokDisplay-Regular.woff2") format("woff2"),
    url("#{$font-path}/TikTokDisplay-Regular.woff") format("woff"),
    url("#{$font-path}/TikTokDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TikTok Display";
  src: url("#{$font-path}/TikTokDisplay-Medium.eot");
  src: url("#{$font-path}/TikTokDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/TikTokDisplay-Medium.woff2") format("woff2"),
    url("#{$font-path}/TikTokDisplay-Medium.woff") format("woff"),
    url("#{$font-path}/TikTokDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TikTok Display";
  src: url("#{$font-path}/TikTokDisplay-Bold.eot");
  src: url("#{$font-path}/TikTokDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/TikTokDisplay-Bold.woff2") format("woff2"),
    url("#{$font-path}/TikTokDisplay-Bold.woff") format("woff"),
    url("#{$font-path}/TikTokDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Machina";
  src: url("#{$font-path}/PPNeueMachina-PlainLight.woff2") format("woff2"),
    url("#{$font-path}/PPNeueMachina-PlainLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Machina";
  src: url("#{$font-path}/PPNeueMachina-PlainUltrabold.woff2") format("woff2"),
    url("#{$font-path}/PPNeueMachina-PlainUltrabold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Machina";
  src: url("#{$font-path}/PPNeueMachina-PlainRegular.woff2") format("woff2"),
    url("#{$font-path}/PPNeueMachina-PlainRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Changa";
  src: url("#{$font-path}/Changa-Regular.woff2") format("woff2"),
    url("#{$font-path}/Changa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
