@media only screen {
  .no-spacer {
    margin: 0 !important;
  }

  .no-top-spacer {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .no-bottom-spacer {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .no-left-spacer {
    margin-#{$default-direction}: 0 !important;
    padding-#{$default-direction}: 0 !important;
  }

  .no-right-spacer {
    margin-#{$opposite-direction}: 0 !important;
    padding-#{$opposite-direction}: 0 !important;
  }

  .top-spacer--xl {
    margin-top: 3rem !important;
  }

  .top-spacer--l {
    margin-top: 2.5rem !important;
  }

  .top-spacer--m {
    margin-top: 2rem !important;
  }

  .top-spacer {
    margin-top: 1.5rem !important;
  }

  .top-spacer--s {
    margin-top: 1rem !important;
  }

  .top-spacer--xs {
    margin-top: 0.5rem !important;
  }

  .bottom-spacer--xl {
    margin-bottom: 3rem !important;
  }

  .bottom-spacer--l {
    margin-bottom: 2.5rem !important;
  }

  .bottom-spacer--m {
    margin-bottom: 2rem !important;
  }

  .bottom-spacer {
    margin-bottom: 1.5rem !important;
  }

  .bottom-spacer--s {
    margin-bottom: 0.75rem !important;
  }

  .bottom-spacer--xs {
    margin-bottom: 0.5rem !important;
  }
}

@include -zf-each-breakpoint {
  @for $i from 1 through $block-grid-max {
    .#{$-zf-size}-up-#{$i} {
      .column:last-child,
      .columns:last-child {
        @include grid-column-end;
      }
    }
  }
}

// ::selection {
//     color: #fff;
//     background: rgba(0, 51, 102, 0.41);
// }

//input::placeholder{color: $steel-blue}

html {
  // scroll-behavior: smooth;
  top: 0 !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gutters-1x {
  @include xy-gutters(20, "margin", $negative: true);

  > .cell {
    @include xy-gutters(0, "margin", $negative: false);
  }
}

.gutters-2x {
  @include xy-gutters(30, "margin", $negative: true);

  > .cell {
    @include xy-gutters(30, "padding", $negative: false);
  }

  @include breakpoint(large) {
    @include xy-gutters(60, "margin", $negative: true);

    > .cell {
      @include xy-gutters(60, "padding", $negative: false);
    }
  }
}

.gutters-margin-1x {
  @include xy-gutters(20, "margin", $negative: true);

  .cell {
    @include xy-gutters(20, "margin", $negative: false);
  }
}

.gutters-margin-2x {
  //@include xy-gutters(40, 'margin', $negative: true);
  .cell {
    //@include xy-gutters(40, 'margin', $negative: false);
    @include xy-gutters(40, margin, right left, false);
  }
}

/*@include breakpoint(large) {
[dir="rtl"] {
    .large-uncentered, .large-push-0, .large-pull-0 {
    float: $default-direction;
    margin-#{$opposite-direction}: 0;
    margin-#{$default-direction}: 0;
    position: static;
    }
}
}*/

.vertical-center {
  @include vertical-center();
}

.center-element {
  position: absolute;
  #{$opposite-direction}: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.round {
  border-radius: 1000px;
}

.use-loader {
  height: 370px;
  overflow: hidden;
  background: url(../img/ajax-loader.gif) no-repeat center center transparent;

  > div {
    opacity: 0;
    visibility: hidden;
    height: 400px;
  }
}

.use-loader.slick-initialized.slick-loaded {
  background: none;
  height: auto;
  overflow: visible;

  > div {
    height: auto;
  }

  &.slider-nav {
    background: $black;
  }
}

.use-loader.slick-initialized.slick-loaded .slick-list,
.use-loader.slick-initialized.slick-loaded button {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease-in;
}

.round {
  border-radius: 1000px;
  overflow: hidden;
}

/*
.input-group.with-radius > *:first-child {
border-radius: 0 5px 5px 0;
}

.input-group.with-radius > *:last-child > * {
border-radius: 5px 0 0 5px;
}
*/

.show-for-printable-area {
  display: none;
}

input {
  &.radius {
    //.input-group > &:first-child
    border-radius: 3px;
  }

  .search-form & {
    &.input-group-field {
      border-radius: 3px !important;
    }
  }
}

textarea {
  height: 10rem;
}

a {
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;

  [dir="rtl"] & {
    line-height: 1.4;
  }
}

/*
.off-canvas {
height: 100%;
}

.js-off-canvas-exit {
z-index: 10;
}
*/

/*[dir="rtl"] .close-button {
#{$default-direction}: auto;
#{$opposite-direction}: 1rem;
}*/

/*.placeholder {
font-size: 0.875rem;
color: #aaa;
}*/

p {
  font-size: $paragraph-font-size;
}

figure {
  margin: 0 0 0.5rem;

  &.round {
    overflow: hidden;
  }
}

.leaderboard-ad {
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
}

// button,
// .button {
//   &.radius {
//     border-radius: 3px !important;
//   }

//   &.primary {
//     color: $white;

//     &:hover {
//       background-color: $secondary-color;
//       color: $white;
//     }
//   }

//   &.secondary:hover {
//     background-color: $primary-color;
//     color: $white;
//   }

//   &.white-2 {
//     background-color: $white;
//     color: $black;

//     &:hover {
//       background-color: $primary-color;
//       color: $white;
//     }
//   }

// }

.button {
  font-weight: 800;
  display: inline-flex;
  align-items: center;

  &.white {
    &.hollow {
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }

    &.colored {
      background-color: $white;
      color: $primary-color;

      &:hover {
        background-color: rgba($white, 0.9);
        color: $primary-color;
      }
    }
  }
}

.bg-white {
  background-color: $white;
}

.bg-gray {
  background-color: $bg-gray;
}

.ft-sz-15 {
  font-size: rem-calc(15);
}

.ft-sz-16 {
  font-size: rem-calc(16);
}

.ft-sz-18 {
  font-size: rem-calc(18);
}

#main-header {
  //position: fixed;
  width: 100%;
  height: 60px;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: $header-bg;
  box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 1px;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;

  .terms & {
    background-color: $header-bg;
    position: relative;
  }

  @include breakpoint(small only) {
    .grid-container {
      max-width: 480px;
      padding-#{$opposite-direction}: 1rem;
      padding-#{$default-direction}: 1rem;
    }
  }
}

.main-header-inner {
  padding: 1rem 0;
  z-index: 9;
  width: 100%;

  .menu {
    li {
      a {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        padding-#{$default-direction}: 0;
        padding-bottom: 13px;
        padding-#{$opposite-direction}: 71px;
        color: $black;

        &:hover {
          color: $primary-color;
        }

        [dir="rtl"] & {
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
        }
      }

      .register-btn {
        border-bottom: 2px solid #ffffff;
      }
    }
  }

  .language {
    padding-#{$opposite-direction}: 28px;
    background: url(../img/svg/icon-world.svg) no-repeat #{$opposite-direction};
    background-size: 20px;
    a {
      font-size: 14px;
      line-height: 23px;
      font-weight: 400;
      font-family: $font-primary;
      [dir="rtl"] & {
        //font-weight: 600;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .header-buttons {
    display: flex;
    align-items: center;
    gap: 71px;

    @include breakpoint(small only) {
      margin-#{$default-direction}: auto;
    }
  }

  &.hc-sticky {
    background-color: $white;
    position: fixed;
    width: 100%;
    padding: 1rem 0;
  }
}

.logo-holder {
  display: flex;
  align-items: center;
  img {
    width: 118px;
    @include breakpoint(small only) {
      width: 103px;
    }
  }

  a {
    &:not(:last-child) {
      border-right: 2px solid #000000;
      margin-right: 30px;
      padding-right: 30px;
    }
    &:not(:only-child):not(:last-child) {
      //padding: 1rem 2rem 1rem 0;
    }
  }

  [dir="rtl"] & {
    flex-direction: row-reverse;
  }
}

.toggle-nav-panel {
  color: $white;
  cursor: pointer;

  @include breakpoint(small only) {
    font-size: rem-calc(22);
  }
}

.canvas-panel {
  padding: 2rem;

  .logo-holder {
    img {
      width: 100%;
      max-width: 400px;
    }
  }
}

.close-panel {
  height: 26px;
  width: 26px;
  margin-bottom: 2rem;
}

.menu-panel {
  margin-top: 3rem;

  li {
    a {
      color: $white;
      padding-#{$default-direction}: 0;
      font-size: 20px;

      &:hover {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    &:last-child {
      margin-top: 3rem;
    }
  }
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: clip;
}

main,
header,
footer {
  position: relative;
}

.center-xy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

main {
  margin: 0 auto;
  padding: 0;

  .front & {
    //padding: 0;
  }

  .grid-container {
    //padding-#{$opposite-direction}: 0;
    //padding-#{$default-direction}: 0;
    @include breakpoint(medium) {
      //padding-#{$opposite-direction}: 1.5rem;
      //padding-#{$default-direction}: 1.5rem;
    }

    @include breakpoint(large) {
      //padding-#{$opposite-direction}: 1rem;
      //padding-#{$default-direction}: 1rem;
    }

    @include breakpoint(1440) {
      //padding-#{$opposite-direction}: 1.5rem;
      //padding-#{$default-direction}: 1.5rem;
    }
  }

  @include breakpoint(small only) {
    .grid-container {
      max-width: 480px;
      padding-#{$opposite-direction}: 1rem;
      padding-#{$default-direction}: 1rem;
    }
  }

  @include breakpoint(medium only) {
    .grid-container {
      //padding-#{$opposite-direction}: 0.75rem;
      //padding-#{$default-direction}: 0.75rem;
      max-width: 816px;
    }
  }
}

.about {
  &__content {
    @include breakpoint(large) {
      padding-bottom: 2rem;
    }
  }

  &__content-media {
    flex: auto;
  }

  &__content-img {
    @include breakpoint(medium down) {
      order: 2;
      margin: 0 auto;
    }

    @include breakpoint(large) {
      float: #{$opposite-direction};
      margin-#{$default-direction}: 40px;
    }
  }

  &__content-media {
    position: relative;

    img {
      [dir="rtl"] & {
        transform: scaleX(-1);
      }
    }

    .btn-play {
      cursor: pointer;
      position: absolute;
      top: 50%;
      #{$default-direction}: 50%;
      transform: translate(-50%, -50%);
      background: #b979ff;
      width: 106px;
      height: 106px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 300ms ease opacity;

      [dir="rtl"] & {
        transform: translate(50%, -50%);
      }

      &:after {
        content: "";
        width: 48px;
        height: 52px;
        background: url(../img/svg/icon-play.svg) no-repeat;
        background-size: contain;
        display: block;
      }

      &:hover {
        opacity: 0.9;
        transition: 300ms ease opacity;
      }
    }
  }

  &__content-info {
    display: flex;
    gap: 44px;
    align-items: flex-start;

    > div {
      flex: 1;
    }

    @include breakpoint(medium down) {
      display: inline-flex;
      width: 100%;
      flex-wrap: wrap;
      margin-top: 20px;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .highlight {
      @include breakpoint(large) {
        font-size: 50px;
        line-height: 65px;
      }

      p {
        a {
          color: $primary-color;
          font-weight: bold;
        }

        font-size: 28px;
        line-height: 38px;

        @include breakpoint(large) {
          font-size: 42px;
          line-height: 62px;

          [dir="rtl"] & {
            font-weight: 400;
            font-size: 42px;
            line-height: 65px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p {
      font-weight: 300;
      font-size: 34px;

      @include breakpoint(small only) {
        font-size: 14px;
        margin-bottom: 1rem;
      }

      @include breakpoint(large) {
        line-height: 50px;
      }

      strong {
        color: $alt-primary;
      }
    }
  }
}

.custom-btn {
  .menu & {
    width: 190px;
    height: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes leftToRight {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slide-left {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.award {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  padding: 60px 30px;
  border-radius: 18px;
  position: relative;
  border: 1px solid #dad6df;
  border-radius: 8px;
  color: #000000;
  background: #ffffff;
  margin-top: 148px;

  @include breakpoint(large) {
    font-size: 38px;
    line-height: 50px;

    [dir="rtl"] & {
      font-weight: 400;
      font-size: 34px;
      line-height: 50px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    background: url("../img/svg/winner.svg") no-repeat center center;
    width: 148px;
    height: 148px;
    top: -148px;
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    margin: 0 auto;
  }
}

.section {
  @include breakpoint(small only) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__text {
    margin-bottom: 80px;

    @include breakpoint(large) {
      padding: 0 64px;
    }

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 38px;

      [dir="rtl"] & {
        line-height: 44px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    text-transform: uppercase;

    h2 {
      color: $primary-color;
      position: relative;
      padding-#{$default-direction}: 64px;
      margin-bottom: 42px;
      font-weight: normal;
      @include breakpoint(small only) {
        padding-#{$default-direction}: 40px;
      }

      @include breakpoint(large) {
        [dir="rtl"] & {
          font-weight: 700;
          font-size: 46px;
          line-height: 75px;
        }
      }

      &:before {
        width: 40px;
        content: "";
        height: 2px;
        background-color: $primary-color;
        top: 28px;
        display: block;
        position: absolute;
        #{$default-direction}: 0;

        @include breakpoint(small only) {
          width: 20px;
          top: 10px;
        }
      }
    }

    &.style-1 {
      @include breakpoint(small only) {
        display: flex;
        justify-content: center;
      }
    }

    &.style-2 {
      text-align: center;
      position: relative;
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      @include breakpoint(large) {
        margin-bottom: 78px;
      }

      &:before {
        content: "";
        height: 2px;
        width: 40px;
        background-color: $black;
        // position: absolute;
        // #{$default-direction}: 0;
        // top: 10px;
        // @include breakpoint(large) {
        //     top: 20px;
        // }
      }

      &:after {
        content: "";
        height: 2px;
        width: 40px;
        background-color: $black;
        // position: absolute;
        // #{$opposite-direction}: 0;
        // top: 10px;
        // @include breakpoint(large) {
        //     top: 20px;
        // }
      }
    }

    &.style-3 {
      position: relative;
      padding-#{$default-direction}: 64px;

      @include breakpoint(small only) {
        padding-#{$default-direction}: 40px;
      }

      &:before {
        content: "";
        height: 2px;
        width: 20px;
        background-color: #ffffff;
        position: absolute;
        #{$default-direction}: 0;
        top: 10px;

        @include breakpoint(large) {
          width: 40px;
          top: 16px;
        }

        .section-agenda & {
          background-color: $white;
        }

        .section-location & {
          background-color: #000000;
        }
      }
    }
  }

  &-agenda //, &-form
  {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include breakpoint(large) {
      padding-top: 5rem;
    }
  }

  &-about {
    // background: url("../img/svg/about-bg.svg") left top no-repeat;
    // background-size: cover;

    @include breakpoint(large) {
      padding-top: 176px;
      padding-bottom: 290px;
    }
  }

  &-speakers {
    color: #000000;
    position: relative;

    @include breakpoint(large) {
      padding-top: 32px;
      padding-bottom: 108px;
    }
  }

  &-guest-speakers {
    background: url("../img/bg/bg-pattern-2.svg") left top no-repeat;

    @include breakpoint(large) {
      padding-top: 172px;
    }
  }

  &-form {
    background: #231b2a;
    color: #ffffff;

    // @include breakpoint(large) {
    //     padding-top: 154px;
    //     padding-bottom: 164px;
    // }
  }

  &-agenda {
    @include breakpoint(large) {
      padding-top: 72px;
      padding-bottom: 168px;
    }
  }
}

.section-agenda {
  .section__title {
    margin-bottom: 43px;

    h2 {
      margin-bottom: 30px;

      @include breakpoint(large) {
        font-size: 60px;
        line-height: 72px;

        [dir="rtl"] & {
          font-size: 60px;
          line-height: 98px;

          &:before {
            top: 43px;
          }
        }
      }
    }
  }

  .agenda {
    &-img {
      display: flex;
      flex-direction: column;
      gap: 26px;
    }

    &-text {
      @include breakpoint(large) {
        margin-bottom: 120px;
      }

      p {
        font-size: 18px;
        line-height: 28px;

        @include breakpoint(large) {
          font-size: 35px;
          line-height: 50px;

          [dir="rtl"] & {
            font-weight: 400;
            font-size: 35px;
            line-height: 55px;
          }
        }
      }
    }

    &-list {
      p {
        font-size: 18px;
        line-height: 28px;

        @include breakpoint(large) {
          margin-bottom: 38px;
          font-weight: 300;
          font-size: 35px;
          line-height: 44px;

          [dir="rtl"] & {
            font-weight: 700;
            font-size: 35px;
            line-height: 55px;
          }
        }
      }

      ul {
        margin-#{$default-direction}: 0;
        list-style-position: inside;

        li {
          font-size: 16px;
          margin-bottom: 20px;
          line-height: 23px;

          @include breakpoint(large) {
            font-size: 33px;
            margin-bottom: 32px;
            line-height: 42px;

            [dir="rtl"] & {
              font-size: 35px;
              line-height: 57px;
            }
          }
        }
      }
    }
  }
}

.speaker-list {
  &__img {
    margin-bottom: 22px;
  }

  &__item {
    @include breakpoint(medium) {
      width: 250px;
    }

    @include breakpoint(large) {
      width: 400px;
    }

    .guest-carousel & {
      width: 300px;

      @include breakpoint(medium) {
        width: 130px;
      }

      @include breakpoint(large) {
        width: 328px;
      }
    }
  }

  &__info {
    &-name {
      font-size: 22px;
      color: $secondary-color;

      @include breakpoint(large) {
        font-size: 30px;
        line-height: 36px;

        [dir="rtl"] & {
          font-weight: 700;
          font-size: 30px;
          line-height: 49px;
        }
      }
    }

    &-position {
      margin-bottom: 12px;
      font-size: 19px;
      color: #ffffff;

      @include breakpoint(large) {
        font-size: 22px;
        line-height: 26px;

        [dir="rtl"] & {
          font-size: 21px;
          font-weight: 400;
          line-height: 35px;
        }
      }
    }

    &-details {
      p {
        font-size: 16px;
        color: #c5c0cb;

        [dir="rtl"] & {
          font-weight: 400;
        }

        @include breakpoint(large) {
          font-size: 22px;
          line-height: 26px;

          [dir="rtl"] & {
            font-weight: 400;
            line-height: 35px;
          }
        }
      }
    }
  }

  &.guest-list {
    .speaker-list__item {
      margin-#{$opposite-direction}: 70px;
    }
  }
}

.speaker-carousel {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    background: #231b2a;
    width: 100%;
    height: 100%;
    top: 245px;
    #{$default-direction}: 0;
    display: block;
  }

  @include breakpoint(medium down) {
    padding: 0 1rem;
  }

  @include breakpoint(large) {
    //padding-#{$default-direction}: calc((100vw - 1514px) / 2);
    //margin-bottom: 114px;
  }

  .splide-carousel {
    max-width: 1400px;
    margin: 0 auto;
  }

  cursor: grabbing;

  &--wrap {
    white-space: nowrap;
    display: inline-flex;
    align-items: flex-start;
  }

  &--item {
    display: inline-block;
    width: 328.47px;
    user-select: none;
    white-space: initial;
    flex: 1 0 auto;

    &:not(:last-child) {
      margin-#{$opposite-direction}: 100px;
    }
  }

  &--progress {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    bottom: 97px;
    width: 1145px;
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    margin: 0 auto;
    height: 1px;
    background: #5c5c5c;

    &-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      #{$default-direction}: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      transform: scaleX(0);
      transform-origin: 0% 0%;
    }
  }
}

.guest-carousel {
  margin-bottom: 20px;

  @include breakpoint(medium down) {
    margin-#{$default-direction}: 20px;
  }

  @include breakpoint(large) {
    padding-#{$default-direction}: calc((100vw - 1514px) / 2);
    margin-bottom: 114px;
  }
}

.splide {
  .my-carousel-progress {
    background: #dcdcdc;
    margin: 1rem auto;
    width: 80%;

    @include breakpoint(large) {
      width: 1145px;
      margin: 120px auto;
    }

    .speaker-carousel & {
      background: #5c5c5c;
    }
  }

  .my-carousel-progress-bar {
    background: $black;
    height: 2px;
    -webkit-transition: width 400ms ease;
    transition: width 400ms ease;
    width: 0;

    .speaker-carousel & {
      background: $secondary-color;
    }
  }

  &__slide {
    margin-#{$opposite-direction}: -2rem;

    .guest-carousel & {
      margin-#{$default-direction}: 1rem;
    }
  }

  &__progress {
    //margin-#{$default-direction}: calc(((-1px - (100vw - 1514px)) / 2) + 1px);
  }
}

.section-form {
  .iframe-wrapper & {
    padding-top: 5rem;
    padding-bottom: 5rem;
    @include breakpoint(large) {
      padding-top: 154px;
      padding-bottom: 164px;
    }
  }
  .section__title {
    @include breakpoint(medium) {
      display: flex;
      justify-content: space-between;
    }

    h4 {
      [dir="rtl"] & {
        font-weight: 400;
      }
    }

    .deadline {
      color: $secondary-color;
      font-weight: normal;
      @include breakpoint(large) {
        [dir="rtl"] & {
          font-weight: 500;
          font-size: 30px;
          line-height: 49px;
        }
      }

      a {
        font-weight: bold;
        color: $secondary-color;
      }
    }
  }

  .criteria {
    margin-bottom: 15px;

    @include breakpoint(large) {
      margin-bottom: 83px;
      margin-#{$default-direction}: 3.3rem;
    }

    &-title {
      margin-bottom: 20px;

      @include breakpoint(large) {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 50px;
        margin-top: 50px;

        [dir="rtl"] & {
          font-weight: 400;
          font-size: 42px;
          line-height: 66px;
        }
      }
    }

    &-label {
      font-weight: bold;
      margin-bottom: 22px;

      [dir="rtl"] & {
        font-weight: 400;
      }

      @include breakpoint(large) {
        font-size: 22px;
        line-height: 26px;

        [dir="rtl"] & {
          font-weight: 400;
          font-size: 22px;
          line-height: 35px;
        }
      }
    }

    p {
      @include breakpoint(small only) {
        font-size: 16px;
      }
    }

    &-details {
      padding-#{$default-direction}: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;

      @include breakpoint(large) {
        padding-#{$default-direction}: 40px;
      }

      [dir="rtl"] & {
        padding-right: 40px;
        padding-left: 0;
      }

      &:before {
        content: "";
        position: absolute;
        #{$default-direction}: 0;
        top: 0;
        height: 95%;
        width: 1px;
        background-color: #dcdcdc;
      }
    }

    ul {
      list-style: auto;
      margin-bottom: 10px;

      @include breakpoint(large) {
        font-size: 22px;
        line-height: 50px;

        [dir="rtl"] & {
          font-weight: 400;
          font-size: 22px;
          line-height: 50px;
        }
      }
    }
  }

  iframe {
    min-height: 3309px;
    [dir="rtl"] & {
      min-height: 3100px;
    }

    @include breakpoint(medium) {
      min-height: 2174px;
    }

    @include breakpoint(large) {
      min-height: 2620px;
      [dir="rtl"] & {
        min-height: 2470px;
      }
    }
  }
}

.form-schedule {
  @include breakpoint(large) {
    // width: 1197px;
    margin: 0 auto;
  }

  > .cell {
    margin-bottom: 96px;
  }

  label {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 12px;

    h3 {
      margin-bottom: 0;
      margin-#{$opposite-direction}: 5px;
    }

    strong {
      margin-#{$opposite-direction}: 5px;
    }

    @include breakpoint(large) {
      font-size: 30px;
    }
  }

  input[type="text"],
  input[type="email"],
  textarea {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: auto;
    margin-bottom: 83px;

    ::placeholder {
      color: #dcdcdc;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #dcdcdc;
    }

    ::-ms-input-placeholder {
      color: #dcdcdc;
    }
  }

  input[type="text"],
  input[type="email"],
  textarea {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;

    @include breakpoint(large) {
      padding: 1.5rem 1rem;
    }
  }

  .input-file {
    display: block;
    padding: 17px 24px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: auto;
    margin-bottom: 83px;
    position: relative;

    input {
      width: 1px;
      height: 1px;
      opacity: 0;
    }

    &:after {
      content: "";
      width: 28px;
      height: 31px;
      background: url(../img/svg/icon-upload.svg) no-repeat;
      background-size: contain;
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      #{$opposite-direction}: 21px;
    }

    &.upload-cv {
      &:after {
        #{$opposite-direction}: 50%;
        transform: translateY(-50%) translateX(50%);
      }
    }
  }

  .upload-cv {
    background: none !important;
    border: 1px solid #dcdcdc;
  }

  textarea {
    margin-bottom: 12px;
  }

  .button {
    padding: 16px 48px;
    margin: 3rem auto 0;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #231b2a;
    background: #ffffff;
    border-radius: 8px;

    &:hover {
      background-color: rgba($black, 0.9);
    }
  }
}

.important-message {
  margin: 20px auto;

  @include breakpoint(large) {
    width: 80%;
    margin: 50px auto 0;
  }

  &__item {
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 31px;

    @include breakpoint(large) {
      font-size: 18px;
    }

    img {
      margin-#{$opposite-direction}: 20px;
    }

    p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.google-maps {
  iframe {
    width: 100%;
  }
  #map-canvas {
    height: 384px;
    iframe {
      #{$default-direction}: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      border: none;
    }
  }
}

.section-location {
  background-color: #ffffff;
  color: #000000;

  &__title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: none;

    @include breakpoint(small only) {
      margin-right: 16px;
      margin-left: 16px;
    }
    @include breakpoint(large) {
      margin-top: 114px;
    }

    p {
      font-size: 16px;
      margin-bottom: 0;
      text-transform: none;

      @include breakpoint(large) {
        font-size: 22px;
      }
    }
  }
}

#main-footer {
  background-color: $footer-bg;
  color: $white;
}

.main-footer {
  &__content {
    padding: 135px 0;

    @include breakpoint(medium down) {
      padding: 60px 0;
    }
  }

  .logo {
    //width: 206px;
    @include breakpoint(small only) {
      text-align: center;
      margin: 0 auto;
    }

    @include breakpoint(medium only) {
      padding: 2rem;
    }
  }
}

.main-footer-before {
  color: $white;

  h6 {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 2.8px;
    color: $white;

    opacity: 0.6;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;

    margin-bottom: 1rem;

    @include breakpoint(large) {
      font-size: 50px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .button {
    color: $white;

    border-radius: 30px;
    padding: 1.5rem 2.5rem;
    font-weight: 800;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    line-height: 0;
  }
}

.copyright {
  p {
    font-size: 14px;
    margin-bottom: 0;
    color: #c5c0cb;
  }
}

.more-information {
  margin: 0 auto;

  @include breakpoint(large) {
    width: 40rem;
  }
}

.main-footer-after {
  .cell {
    @include breakpoint(medium down) {
      margin-bottom: 1rem;
    }
  }
}

.terms {
  .article {
    ol {
      li {
        &::marker {
          font-size: 19px;
        }

        padding-#{$default-direction}: 10px;

        a {
          color: $primary-color;

          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }

  .main-footer & {
    a {
      color: $white;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.share-icon__btns {
  display: flex;
  justify-content: center;

  @include breakpoint(large) {
    justify-content: flex-end;
  }

  a {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 47px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-#{$default-direction}: 1rem;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    img {
      height: 11px;
    }
  }
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.ui-datepicker {
  .ui-datepicker-prev,
  .ui-datepicker-next {
    height: 100%;
    top: 0;
    width: 30px;

    span {
      font-family: "icomoon";
      cursor: pointer;
      text-align: center;
      background-image: none;
      text-indent: 0;
      height: 20px;
      margin-top: -15px;
      font-weight: normal;

      &:before {
        font-family: "icomoon";
        color: #000;
      }
    }
  }

  .ui-datepicker-prev {
    #{$opposite-direction}: 0;

    span {
      #{$default-direction}: auto;
      #{$opposite-direction}: 5px;

      &:before {
        content: "\e91c";
        #{$default-direction}: 0;
      }
    }
  }

  .ui-datepicker-next {
    #{$opposite-direction}: 0;

    span {
      #{$default-direction}: auto;
      #{$opposite-direction}: 10px;

      &:before {
        content: "\e91a";
        #{$opposite-direction}: 0;
      }
    }
  }
}

.ui-state-hover.ui-datepicker-prev-hover,
.ui-state-hover.ui-datepicker-next-hover {
  border: none;

  span {
    &:before {
      color: $primary-color;
    }
  }
}

body.register {
  overflow: hidden;
}

.register-wrap {
  iframe {
    @include breakpoint(small only) {
      height: 2000px;
    }

    min-height: 100vh;
    // min-height: 2000px;
    // @include breakpoint(large){
    //     min-height:1487px;
    // }
  }
}

body.thank-you {
}

.popup {
  position: absolute;
  top: 35%;
  #{$default-direction}: 0;
  #{$opposite-direction}: 0;
  background-color: $white;
  border-radius: 30px;
  width: 541px;
  margin: 0 auto;

  @include breakpoint(small only) {
    width: 95%;
  }

  &__msg {
    margin: 2rem 0;

    p {
      font-size: rem-calc(24);
    }
  }
}

.return {
  position: fixed;
  margin-top: 2rem;
  margin-#{$default-direction}: 2rem;

  @include breakpoint(small only) {
    margin-top: 0.5rem;
    margin-#{$default-direction}: 0.5rem;
  }

  a {
    padding: 0.5rem 1rem 0.6rem;

    i {
      margin-#{$opposite-direction}: 10px;
    }
  }
}

// ============== <> =============== Add your custom styles before this line. ============== <> ===============

.ratio-container {
  position: relative;

  &.abs-element {
    position: absolute;
    #{$opposite-direction}: 0;
    #{$default-direction}: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
    padding-bottom: 56.25%;

    .grid-items & {
      padding-bottom: 56.25%;
    }
  }

  &.video-hero {
    max-height: none;

    @include breakpoint(small only) {
      max-height: none;

      &:after {
        padding-bottom: 100%;
      }
    }
  }

  > a {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    border: 0;
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    bottom: 0;
    content: "";
  }

  img {
    position: absolute;
    top: 0;
    #{$opposite-direction}: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }
}

.reveal-overlay {
  background-color: rgba(10, 10, 10, 0.7);
}

#modal-video {
  padding: 2em;
  max-width: 1200px;
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;

  @include breakpoint(small only) {
    display: flex !important;
  }

  .close-button {
    position: fixed;
    top: 30px;
    right: 30px;
    font-size: 100px;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 8px rgb(0, 0, 0);
    display: block;
    outline: none;

    @include breakpoint(small only) {
      font-size: 70px;
      top: 20px;
      right: 20px;
    }

    :hover {
      color: rgb(142, 216, 248);
      text-shadow: 2px 2px 5px rgb(0, 0, 0);
    }
  }
}
